import React, { useState } from "react";
import {
  Instagram,
  Globe,
  Info,
  CheckCircle2,
  ArrowRight,
  Loader2,
  Building2,
  ImageIcon,
  Clock,
} from "lucide-react";
import Button from "../../../shared/Button";
import { generateUnsplashSearchTerm, searchUnsplashImages } from "../../../api";

const ImageUploadTask = ({
  websiteGoal,
  formData,
  onComplete,
  onContentGenerated,
}) => {
  const [selectedSource, setSelectedSource] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleAIImageGeneration = async () => {
    setIsLoading(true);
    setError(null);

    try {
      // Generate search term and fetch images
      const searchTerm = await generateUnsplashSearchTerm(
        formData,
        websiteGoal
      );
      console.log("Generated search term:", searchTerm);

      const newImages = await searchUnsplashImages(searchTerm, 10);
      console.log("Fetched new images:", newImages);

      // Update content with new images
      const existingContent = JSON.parse(
        localStorage.getItem("generatedContent") || "{}"
      );
      const shuffledImages = [...newImages].sort(() => Math.random() - 0.5);

      const updatedContent = {
        hero: {
          ...existingContent.hero,
          image: shuffledImages[0] || existingContent.hero?.image,
        },
        services:
          existingContent.services?.map((service, index) => ({
            ...service,
            image: shuffledImages[index + 1] || service.image,
          })) || [],
        events:
          existingContent.events?.map((event, index) => ({
            ...event,
            image: shuffledImages[index + 4] || event.image,
          })) || [],
      };

      console.log("Updating content with new images:", updatedContent);
      localStorage.setItem("generatedContent", JSON.stringify(updatedContent));

      // Update UI with new content
      onContentGenerated(updatedContent);
      onComplete();
    } catch (err) {
      console.error("Error in handleAIImageGeneration:", err);
      setError("Failed to generate images. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSourceSelect = async (sourceId) => {
    setSelectedSource(sourceId);
    await handleAIImageGeneration();
  };

  // Get recommendation based on business type and goal
  const getRecommendedSource = () => {
    if (websiteGoal === "events" || websiteGoal === "brand") {
      return "instagram";
    }
    return "gmb";
  };

  const recommendedSource = getRecommendedSource();

  let imageSources = [
    {
      id: "instagram",
      title: "Import from Social Media",
      icon: Instagram,
      description: "Automatically import your photos from Instagram & Facebook",
      isRecommended: recommendedSource === "instagram",
      benefits: [
        "One-click import from both platforms",
        "AI automatically matches photos to website sections",
        "Keeps website fresh with your latest content",
        "Preserves your photo descriptions and tags",
      ],
      requirements: [
        "Business Instagram or Facebook account",
        "At least 9 photos posted",
      ],
      timeEstimate: "Ready in 2 minutes",
    },
    {
      id: "gmb",
      title: "Import from Google Business",
      icon: Building2,
      description: "Get your photos from Google My Business Profile",
      isRecommended: recommendedSource === "gmb",
      benefits: [
        "Import your verified business photos",
        "AI organizes photos by category",
        "Maintain consistent brand imagery",
        "Use your highest-rated images",
      ],
      requirements: [
        "Verified Google Business Profile",
        "At least 5 business photos",
      ],
      timeEstimate: "Ready in 3 minutes",
    },
    {
      id: "website",
      title: "Import from Current Website",
      icon: Globe,
      description: "We'll get images from your existing website",
      isRecommended: recommendedSource === "website",
      compact: true,
      timeEstimate: "Ready in 5 minutes",
    },
    {
      id: "upload",
      title: "Upload Your Own Photos",
      icon: ImageIcon,
      description: "Choose and arrange photos manually",
      isRecommended: false,
      compact: true,
      timeEstimate: "30-45 minutes",
    },
  ];

  // Reorder sources to put recommended first
  imageSources = [
    ...imageSources.filter((source) => source.isRecommended),
    ...imageSources.filter((source) => !source.isRecommended),
  ];

  if (isLoading) {
    return (
      <div className="p-6">
        <div className="text-center py-8">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600 mx-auto mb-4"></div>
          <h3 className="font-medium text-gray-900 mb-1">
            Setting Up Your Images...
          </h3>
          <p className="text-sm text-gray-500">
            We're importing and optimizing your images for the perfect website
            layout
          </p>
        </div>

        <div className="mt-8 space-y-2">
          <div className="flex justify-between text-sm">
            <span className="font-medium">Progress</span>
            <span>Processing...</span>
          </div>
          <div className="h-2 bg-gray-100 rounded-full overflow-hidden">
            <div className="h-full bg-blue-600 animate-pulse rounded-full w-3/4" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-6 space-y-6">
      {/* Context Banner */}
      <div className="bg-blue-50 p-4 rounded-lg space-y-2">
        <div className="flex items-center gap-2 text-blue-800 font-medium">
          <Clock className="h-5 w-5 text-blue-600" />
          Save Hours of Work with Smart Import
        </div>
        <p className="text-sm text-blue-700">
          Instead of spending hours uploading and arranging images manually, let
          us automatically import your existing photos and place them perfectly
          throughout your website.
        </p>
      </div>

      {/* Image Sources */}
      <div className="space-y-4">
        {imageSources.map((source) => (
          <div
            key={source.id}
            className={`
                            border rounded-lg overflow-hidden transition-all
                            ${
                              selectedSource === source.id
                                ? "border-blue-500 bg-blue-50"
                                : "hover:border-gray-300"
                            }
                            ${
                              source.isRecommended
                                ? "border-blue-200 bg-blue-50/50"
                                : ""
                            }
                        `}
          >
            <div className="p-4">
              <div className="flex items-start gap-3">
                <source.icon
                  className={`h-5 w-5 mt-1 ${
                    source.isRecommended ? "text-blue-600" : "text-gray-600"
                  }`}
                />
                <div className="flex-1">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center gap-2">
                      <h3 className="font-medium text-gray-900">
                        {source.title}
                      </h3>
                      {source.isRecommended && (
                        <span className="text-xs bg-blue-100 text-blue-700 px-2 py-0.5 rounded-full">
                          Recommended
                        </span>
                      )}
                    </div>
                    <span className="text-sm text-gray-500 flex items-center gap-1">
                      <Clock className="h-3 w-3" />
                      {source.timeEstimate}
                    </span>
                  </div>
                  <p className="text-sm text-gray-600 mt-1">
                    {source.description}
                  </p>

                  {/* Detailed benefits for recommended option */}
                  {source.isRecommended && !source.compact && (
                    <div className="mt-4 space-y-4">
                      <div className="space-y-2">
                        {source.benefits.map((benefit, idx) => (
                          <div
                            key={idx}
                            className="flex items-start gap-2 text-sm"
                          >
                            <CheckCircle2 className="h-4 w-4 text-green-500 mt-0.5" />
                            <span>{benefit}</span>
                          </div>
                        ))}
                      </div>

                      <div className="bg-white rounded-lg p-3">
                        <h4 className="text-sm font-medium text-gray-700 mb-2">
                          Quick Setup Requirements:
                        </h4>
                        <ul className="space-y-1">
                          {source.requirements.map((req, idx) => (
                            <li
                              key={idx}
                              className="text-sm text-gray-600 flex items-center gap-2"
                            >
                              <span className="h-1 w-1 bg-gray-400 rounded-full"></span>
                              {req}
                            </li>
                          ))}
                        </ul>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <Button
                className="w-full mt-4"
                variant={selectedSource === source.id ? "default" : "outline"}
                onClick={() => handleSourceSelect(source.id)}
                disabled={isLoading}
              >
                {isLoading && selectedSource === source.id ? (
                  <>
                    <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                    Importing Images...
                  </>
                ) : (
                  <>
                    {source.isRecommended ? "Start Quick Import" : "Select"}
                    <ArrowRight className="h-4 w-4 ml-2" />
                  </>
                )}
              </Button>
            </div>
          </div>
        ))}
      </div>

      {error && (
        <div className="bg-red-50 text-red-600 p-3 rounded-lg text-sm">
          {error}
        </div>
      )}

      {/* Helper text */}
      <div className="text-sm text-gray-500 flex items-center gap-2">
        <Info className="h-4 w-4" />
        <span>You can always modify or add more photos later</span>
      </div>
    </div>
  );
};

export default ImageUploadTask;
