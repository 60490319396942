import React, { useEffect, useState } from "react";
import BrowserBar from "./BrowserBar";
import Navigation from "./Navigation";
import HeroSection from "./HeroSection";
import ServicesSection from "./ServicesSection";
import FormSection from "./FormSection";
import EventsSection from "./EventsSection";
import Footer from "./Footer";

const Preview = ({ formData, previewContent }) => {
  const [formKey, setFormKey] = useState(0);

  useEffect(() => {
    // Debug incoming props
    console.log("Preview - Initial previewContent:", previewContent);
    console.log("Preview - Initial formData:", formData);
    // Log specific footer content from previewContent
    console.log("Footer content from previewContent:", previewContent?.footer);

    // Log the final merged footer content
    console.log("Final merged footer content:", content.footer);
  }, [previewContent, formData]);

  useEffect(() => {
    if (previewContent?.form) {
      setFormKey((prev) => prev + 1);
    }
  }, [previewContent?.form]);

  // Merged content configuration
  const content = {
    hero: {
      businessName: formData.businessName,
      title: previewContent?.hero?.title || formData.businessName,
      subtitle:
        previewContent?.hero?.subtitle ||
        "Exclusive Pop-up Events & Culinary Experiences",
      image: previewContent?.hero?.image,
    },
    navigation: previewContent?.navigation || {
      links: [
        { label: "Home", href: "#" },
        { label: "Services", href: "#" },
        { label: "Events", href: "#" },
        { label: "Contact", href: "#" },
      ],
      cta: {
        label: "Book Now",
        href: "#book",
      },
    },
    servicesTitle: previewContent?.services?.title || formData.businessName,
    services: previewContent?.services || [
      {
        title: "Pop-up Events",
        description: "Unique dining experiences in unexpected locations",
        image: null,
      },
      {
        title: "Private Dining",
        description: "Personalized culinary experiences for your guests",
        image: null,
      },
      {
        title: "Cooking Classes",
        description: "Interactive cooking sessions for teams and groups",
        image: null,
      },
    ],
    form: previewContent?.form,
    events: previewContent?.events || [
      {
        title: "Summer Garden Dining",
        description: "A unique outdoor dining experience",
        date: "June 15, 2024",
        time: "7:00 PM",
        image: null,
      },
      {
        title: "Wine & Dine Experience",
        description: "Culinary journey paired with fine wines",
        date: "June 22, 2024",
        time: "6:30 PM",
        image: null,
      },
      {
        title: "Chef's Table Evening",
        description: "Intimate dining with interactive cooking",
        date: "June 29, 2024",
        time: "7:30 PM",
        image: null,
      },
    ],
    footer: previewContent?.footer || {
      description:
        "Creating unique culinary experiences and unforgettable moments.",
      sections: [
        {
          title: "Company",
          links: [
            { label: "About Us", href: "#" },
            { label: "Services", href: "#" },
            { label: "Events", href: "#" },
            { label: "Contact", href: "#" },
          ],
        },
        {
          title: "Legal",
          links: [
            { label: "Privacy Policy", href: "#" },
            { label: "Terms of Service", href: "#" },
            { label: "Cookie Policy", href: "#" },
          ],
        },
        {
          title: "Contact",
          links: [
            { label: "Contact Us", href: "#" },
            { label: "Support", href: "#" },
            { label: "Locations", href: "#" },
          ],
        },
      ],
      social: [
        { platform: "facebook", href: "#" },
        { platform: "instagram", href: "#" },
        { platform: "twitter", href: "#" },
      ],
      contact: {
        email: "info@example.com",
        phone: "+1 (555) 123-4567",
        location: "New York, NY",
      },
    },
  };

  // Debug merged content
  console.log("Preview - Merged content:", {
    navigation: content.navigation,
    footer: content.footer,
    previewContentNav: previewContent?.navigation,
    previewContentFooter: previewContent?.footer,
  });

  return (
    <div className="flex-1 overflow-y-auto p-4 md:p-6 lg:p-8 bg-gray-100">
      <div className="max-w-4xl md:max-w-5xl lg:max-w-4xl mx-10">
        <div className="bg-white rounded-lg shadow-md md:shadow-lg overflow-hidden">
          <BrowserBar
            url={`www.${formData.businessName
              .toLowerCase()
              .replace(/\s+/g, "")}.com`}
            className="text-sm md:text-base"
          />
          <div className="h-[calc(100vh-8rem)] md:h-[calc(100vh-9rem)] lg:h-[calc(100vh-10rem)] overflow-y-auto preview-scroll-container">
            <div>
              <div className="relative">
                <div className="absolute inset-0">
                  <Navigation
                    businessName={content.hero.businessName}
                    content={content.navigation}
                  />
                </div>
                <HeroSection
                  businessName={content.hero.businessName}
                  subtitle={content.hero.subtitle}
                  image={content.hero.image}
                />
              </div>

              <div className="px-8">
                <ServicesSection
                  title={content.servicesTitle}
                  items={content.services}
                />
                {content.form && (
                  <FormSection
                    key={`form-${formKey}`}
                    form={content.form}
                    isNew={previewContent?.form?.isNew}
                  />
                )}
                <EventsSection items={content.events} />
              </div>
              <Footer
                businessName={content.hero.businessName}
                content={content.footer}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Preview;
