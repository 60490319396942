const API_BASE_URL =
  "https://personalization-prototype-api.mohammad-teimoori.workers.dev";

export const searchUnsplashImages = async (query, count = 10) => {
  return fetch(
    `${API_BASE_URL}/api/content/unsplash/search-images?q=${query}&count=${count}`
  ).then((res) => res.json());
};

export const generateWebsiteContent = async (formData, websiteGoal) => {
  const data = { formData, websiteGoal };

  return fetch(`${API_BASE_URL}/api/content/generate`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((res) => res.json());
};

export const generateUnsplashSearchTerm = async (formData, websiteGoal) => {
  const data = {
    formData,
    websiteGoal,
  };

  return fetch(`${API_BASE_URL}/api/content/unsplash/generate-search-term`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((res) => res.json());
};

export const refineWebsiteContent = async (
  formData,
  websiteGoal,
  taskData,
  existingContent
) => {
  const data = {
    formData,
    websiteGoal,
    taskData,
    existingContent,
  };

  return fetch(`${API_BASE_URL}/api/content/refine`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((res) => res.json());
};

export const generateFormSuggestions = (formData, websiteGoal) => {
  const data = {
    formData,
    websiteGoal,
  };

  return fetch(`${API_BASE_URL}/api/content/generate-form-suggestions`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(data),
  }).then((res) => res.json());
};
