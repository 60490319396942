import React, { useState, useEffect, useRef } from "react";
import {
  Sparkles,
  Loader2,
  Building2,
  Briefcase,
  FileText,
  ArrowRight,
  Search,
  Check,
} from "lucide-react";
import Button from "../../shared/Button";
import { generateWebsiteContent } from "../../api";

// Industry categories similar to Google My Business
const INDUSTRY_CATEGORIES = [
  {
    category: "Food & Dining",
    subcategories: [
      "Restaurant - Fine Dining",
      "Restaurant - Casual Dining",
      "Restaurant - Fast Casual",
      "Restaurant - Fast Food",
      "Cafe",
      "Bar & Nightclub",
      "Pub & Brewery",
      "Food Truck",
      "Bakery",
      "Pastry Shop",
      "Catering Service",
      "Coffee Shop",
      "Ice Cream Shop",
      "Juice & Smoothie Bar",
      "Pizza Restaurant",
      "Sushi Restaurant",
      "Ethnic Restaurant",
      "Seafood Restaurant",
      "Steakhouse",
      "Dessert Shop",
      "Sandwich Shop",
      "Health Food Restaurant",
      "Vegan/Vegetarian Restaurant",
      "Food Delivery Service",
      "Ghost Kitchen",
    ],
  },
  {
    category: "Professional Services",
    subcategories: [
      "Management Consulting",
      "IT Consulting",
      "Strategy Consulting",
      "HR Consulting",
      "Law Firm - Corporate",
      "Law Firm - Criminal",
      "Law Firm - Family",
      "Law Firm - Immigration",
      "Accounting & Tax Services",
      "Bookkeeping Services",
      "Financial Planning",
      "Investment Advisory",
      "Real Estate - Residential",
      "Real Estate - Commercial",
      "Real Estate - Property Management",
      "Marketing Agency - Digital",
      "Marketing Agency - Traditional",
      "PR Agency",
      "Advertising Agency",
      "IT Services - Support",
      "IT Services - Development",
      "IT Services - Cloud Solutions",
      "Business Process Outsourcing",
      "Translation Services",
      "Insurance - Life & Health",
      "Insurance - Property & Casualty",
      "Recruitment Agency",
      "Architecture Firm",
      "Engineering Services",
      "Environmental Consulting",
    ],
  },
  {
    category: "Health & Wellness",
    subcategories: [
      "Fitness Center",
      "Crossfit Gym",
      "Yoga Studio",
      "Pilates Studio",
      "Spa & Wellness Center",
      "Medical Spa",
      "Gym - Traditional",
      "Gym - Boutique",
      "Personal Training",
      "Group Fitness Studio",
      "Massage Therapy",
      "Physical Therapy",
      "Occupational Therapy",
      "Chiropractic Clinic",
      "Mental Health Practice",
      "Counseling Services",
      "Nutrition Consulting",
      "Weight Loss Center",
      "Alternative Medicine",
      "Acupuncture Clinic",
      "Dental Clinic - General",
      "Dental Clinic - Cosmetic",
      "Orthodontics Practice",
      "Health Food Store",
      "Supplements Store",
      "Rehabilitation Center",
      "Sports Medicine Clinic",
      "Wellness Coaching",
      "Meditation Center",
      "Holistic Health Center",
    ],
  },
  {
    category: "Education & Training",
    subcategories: [
      "Cooking School",
      "Culinary Arts Academy",
      "Language School",
      "ESL Center",
      "Music School",
      "Instrument Training",
      "Voice Training",
      "Dance Studio - Ballet",
      "Dance Studio - Contemporary",
      "Dance Studio - Hip Hop",
      "Art School",
      "Fine Arts Academy",
      "Digital Arts School",
      "Photography School",
      "Tutoring Service - Academic",
      "Tutoring Service - Test Prep",
      "Professional Training Center",
      "Vocational School",
      "Business School",
      "Computer Training Center",
      "Driving School",
      "Early Childhood Education",
      "Special Education Center",
      "STEM Education Center",
      "Adult Education Center",
      "Career Development Center",
      "Corporate Training Provider",
      "Online Education Platform",
      "Educational Technology Provider",
      "Life Skills Training",
    ],
  },
  {
    category: "Events & Entertainment",
    subcategories: [
      "Event Planning - Corporate",
      "Event Planning - Social",
      "Wedding Planning",
      "Wedding Venue",
      "Party Planning Service",
      "Birthday Party Planning",
      "Entertainment Service",
      "Live Music Venue",
      "Theater Company",
      "Comedy Club",
      "Concert Venue",
      "Event Venue - Indoor",
      "Event Venue - Outdoor",
      "Photography Studio - Portrait",
      "Photography Studio - Commercial",
      "Photography Studio - Wedding",
      "Videography Service",
      "DJ Service",
      "Audio Visual Service",
      "Event Rental Service",
      "Event Decoration Service",
      "Event Catering",
      "Event Marketing Service",
      "Virtual Event Platform",
      "Team Building Service",
      "Festival Organization",
      "Conference Planning",
      "Exhibition Service",
      "Sports Event Management",
      "Entertainment Agency",
    ],
  },
  {
    category: "Beauty & Personal Care",
    subcategories: [
      "Hair Salon - Full Service",
      "Hair Salon - Blowout Bar",
      "Hair Color Specialist",
      "Nail Salon",
      "Nail Art Studio",
      "Barber Shop - Traditional",
      "Barber Shop - Modern",
      "Beauty Salon",
      "Day Spa",
      "Medical Spa",
      "Cosmetics Store",
      "Skin Care Clinic",
      "Facial Spa",
      "Waxing Studio",
      "Laser Hair Removal",
      "Eyebrow & Lash Studio",
      "Makeup Artist Studio",
      "Beauty Supply Store",
      "Permanent Makeup Studio",
      "Tanning Salon",
      "Hair Extension Specialist",
      "Men's Grooming Salon",
      "Beauty School",
      "Esthetics Training Center",
      "Natural Beauty Products",
    ],
  },
  {
    category: "Retail",
    subcategories: [
      "Department Store",
      "Clothing Store",
      "Fashion Boutique",
      "Shoe Store",
      "Jewelry Store",
      "Electronics Store",
      "Home Goods Store",
      "Furniture Store",
      "Sporting Goods Store",
      "Bookstore",
      "Gift Shop",
      "Toy Store",
      "Pet Store",
      "Art Gallery",
      "Antique Store",
      "Thrift Store",
      "Specialty Food Store",
      "Wine & Spirits Store",
      "Hardware Store",
      "Office Supply Store",
      "Music Store",
      "Bicycle Shop",
      "Garden Center",
      "Florist",
      "Convenience Store",
      "Digital Products Store",
      "Sustainable Products Store",
      "Accessories Store",
      "Watch Store",
      "Luggage Store",
    ],
  },
  {
    category: "Technology",
    subcategories: [
      "Software Development",
      "Mobile App Development",
      "Web Development",
      "Cloud Services",
      "Cybersecurity Services",
      "Data Analytics",
      "Artificial Intelligence",
      "Machine Learning",
      "IoT Solutions",
      "Blockchain Development",
      "Game Development",
      "Virtual Reality",
      "Augmented Reality",
      "Digital Transformation",
      "IT Infrastructure",
      "Network Solutions",
      "Tech Support Services",
      "Database Management",
      "Systems Integration",
      "DevOps Services",
      "Quality Assurance",
      "Tech Consulting",
      "Hardware Manufacturing",
      "Electronics Repair",
      "Tech Education",
      "Digital Marketing Technology",
      "E-commerce Solutions",
      "FinTech Solutions",
      "Healthcare Technology",
      "EdTech Solutions",
    ],
  },
  {
    category: "Manufacturing & Industrial",
    subcategories: [
      "Food Manufacturing",
      "Beverage Manufacturing",
      "Textile Manufacturing",
      "Clothing Manufacturing",
      "Furniture Manufacturing",
      "Electronics Manufacturing",
      "Automotive Manufacturing",
      "Chemical Manufacturing",
      "Plastic Manufacturing",
      "Metal Manufacturing",
      "Wood Products",
      "Paper Products",
      "Industrial Equipment",
      "Machine Shop",
      "Tool & Die Making",
      "Printing Services",
      "Packaging Services",
      "Custom Fabrication",
      "Assembly Services",
      "Quality Control Services",
      "Industrial Design",
      "Product Development",
      "Prototyping Services",
      "Contract Manufacturing",
      "Industrial Automation",
      "Waste Management",
      "Recycling Services",
      "Energy Production",
      "Raw Materials Processing",
      "Distribution Services",
    ],
  },
  {
    category: "Construction & Home Services",
    subcategories: [
      "General Contracting",
      "Residential Construction",
      "Commercial Construction",
      "Home Renovation",
      "Kitchen & Bath Remodeling",
      "Roofing Services",
      "Plumbing Services",
      "Electrical Services",
      "HVAC Services",
      "Landscaping Services",
      "Interior Design",
      "Architectural Services",
      "Painting Services",
      "Flooring Installation",
      "Window Installation",
      "Door Installation",
      "Home Inspection",
      "Pest Control",
      "Cleaning Services",
      "Moving Services",
      "Storage Services",
      "Home Security",
      "Solar Installation",
      "Pool Services",
      "Fence Installation",
      "Garage Door Services",
      "Handyman Services",
      "Carpentry Services",
      "Masonry Services",
      "Home Staging",
    ],
  },
];

// Flatten categories for search
const ALL_INDUSTRIES = INDUSTRY_CATEGORIES.flatMap((cat) =>
  cat.subcategories.map((sub) => ({
    category: cat.category,
    subcategory: sub,
    searchText: `${cat.category} ${sub}`.toLowerCase(),
  }))
);

const messages = [
  "Saving your business data...",
  "Creating your content...",
  "Optimizing for mobile...",
  "Selecting the best templates...",
  "Crafting your homepage layout...",
  "Enhancing SEO for visibility...",
  "Setting up analytics...",
  "Personalizing user experience...",
  "Preparing marketing strategies...",
  "Finalizing design elements...",
  "Optimizing for search engines...",
];

const InitialForm = ({ formData, setFormData, onNext }) => {
  const [isGenerating, setIsGenerating] = useState(false);
  const [error, setError] = useState(null);
  const [messageIndex, setMessageIndex] = useState(0);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const searchRef = useRef(null);

  // Handle clicks outside of suggestions
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Handle loading messages
  useEffect(() => {
    let messageInterval;
    if (isGenerating) {
      messageInterval = setInterval(() => {
        setMessageIndex((prevIndex) => (prevIndex + 1) % messages.length);
      }, 3000);
    } else {
      setMessageIndex(0);
    }
    return () => clearInterval(messageInterval);
  }, [isGenerating]);

  // Handle search and suggestions
  useEffect(() => {
    if (searchTerm.length > 1) {
      const filteredIndustries = ALL_INDUSTRIES.filter((industry) =>
        industry.searchText.includes(searchTerm.toLowerCase())
      ).slice(0, 5); // Limit to 5 suggestions
      setSuggestions(filteredIndustries);
      setShowSuggestions(true);
    } else {
      setSuggestions([]);
      setShowSuggestions(false);
    }
  }, [searchTerm]);

  const handleIndustrySelect = (suggestion) => {
    setFormData({
      ...formData,
      industryCategory: suggestion.category,
      industry: suggestion.subcategory,
    });
    setSearchTerm(`${suggestion.category} - ${suggestion.subcategory}`);
    setShowSuggestions(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!formData.businessName || !formData.industry || !formData.description) {
      setError("Please fill in all required fields.");
      return;
    }
    setIsGenerating(true);
    setError(null);

    try {
      const generatedContent = await generateWebsiteContent(formData, "brand");
      localStorage.setItem(
        "generatedContent",
        JSON.stringify(generatedContent)
      );
      onNext(generatedContent);
    } catch (err) {
      console.error("Error:", err);
      setError("Failed to generate content. Please try again.");
    } finally {
      setIsGenerating(false);
    }
  };

  return (
    <div className="fixed inset-0 flex flex-col bg-gray-50">
      {/* Fixed Header */}
      <div className="sticky top-0 z-10 bg-white border-b">
        <div className="p-6 flex justify-between items-center">
          <div className="text-2xl font-bold text-blue-600">Jimdo</div>
        </div>
      </div>

      {/* Scrollable Content */}
      <div className="flex-1 overflow-y-auto">
        <div className="py-8 px-4 md:px-8">
          <div className="max-w-2xl mx-auto">
            {/* Title section */}
            <div className="text-center mb-8">
              <h1 className="text-3xl font-bold text-gray-900 mb-3">
                Tell Us About Your Business
              </h1>
              <p className="text-lg text-gray-600">
                We'll use this information to create your personalized website
              </p>
            </div>

            {/* Form */}
            <form onSubmit={handleSubmit} className="space-y-8">
              <div className="space-y-6">
                {/* Business Name */}
                <div className="bg-white p-6 rounded-xl border border-gray-200 hover:border-blue-200 transition-colors">
                  <div className="flex gap-4">
                    <div className="flex-shrink-0">
                      <div className="h-10 w-10 bg-blue-50 rounded-lg flex items-center justify-center">
                        <Building2 className="h-5 w-5 text-blue-600" />
                      </div>
                    </div>
                    <div className="flex-1 space-y-2">
                      <label className="block font-medium text-gray-900">
                        What's your business name?
                        <span className="text-red-500">*</span>
                      </label>
                      <input
                        type="text"
                        value={formData.businessName}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            businessName: e.target.value,
                          })
                        }
                        className="w-full p-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all"
                        placeholder="e.g., Coastal Breeze Cafe"
                        required
                      />
                      <p className="text-sm text-gray-500">
                        This helps us setup your brand name and tailor your
                        website content.
                      </p>
                    </div>
                  </div>
                </div>

                {/* Industry Search */}
                <div className="bg-white p-6 rounded-xl border border-gray-200 hover:border-blue-200 transition-colors">
                  <div className="flex gap-4">
                    <div className="flex-shrink-0">
                      <div className="h-10 w-10 bg-purple-50 rounded-lg flex items-center justify-center">
                        <Briefcase className="h-5 w-5 text-purple-600" />
                      </div>
                    </div>
                    <div className="flex-1 space-y-2" ref={searchRef}>
                      <label className="block font-medium text-gray-900">
                        What's your business type & industry?
                        <span className="text-red-500">*</span>
                      </label>
                      <div className="relative">
                        <div className="relative">
                          <Search className="absolute left-3 top-3 h-5 w-5 text-gray-400" />
                          <input
                            type="text"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            className="w-full pl-10 p-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all"
                            placeholder="Search your business type..."
                            required
                          />
                        </div>

                        {showSuggestions && suggestions.length > 0 && (
                          <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-lg shadow-lg">
                            {suggestions.map((suggestion, index) => (
                              <div
                                key={index}
                                className="p-3 hover:bg-gray-50 cursor-pointer flex items-center justify-between"
                                onClick={() => handleIndustrySelect(suggestion)}
                              >
                                <div>
                                  <div className="font-medium">
                                    {suggestion.subcategory}
                                  </div>
                                  <div className="text-sm text-gray-500">
                                    {suggestion.category}
                                  </div>
                                </div>
                                {formData.industry ===
                                  suggestion.subcategory && (
                                  <Check className="h-5 w-5 text-green-500" />
                                )}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                      <p className="text-sm text-gray-500">
                        We use this to optimise the wording and designs to be
                        specific to your industry.
                      </p>

                      {/* Selected Industry Display */}
                      {formData.industry && (
                        <div className="mt-3 bg-blue-50 p-3 rounded-lg">
                          <p className="text-sm text-blue-700">
                            Selected:{" "}
                            <span className="font-medium">
                              {formData.industryCategory} › {formData.industry}
                            </span>
                          </p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* Description */}
                <div className="bg-white p-6 rounded-xl border border-gray-200 hover:border-blue-200 transition-colors">
                  <div className="flex gap-4">
                    <div className="flex-shrink-0">
                      <div className="h-10 w-10 bg-green-50 rounded-lg flex items-center justify-center">
                        <FileText className="h-5 w-5 text-green-600" />
                      </div>
                    </div>
                    <div className="flex-1 space-y-2">
                      <label className="block font-medium text-gray-900">
                        Can you give us a short description about your Business?
                        <span className="text-red-500">*</span>
                      </label>
                      <textarea
                        value={formData.description}
                        onChange={(e) =>
                          setFormData({
                            ...formData,
                            description: e.target.value,
                          })
                        }
                        className="w-full p-3 border border-gray-200 rounded-lg h-32 focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all"
                        placeholder="Tell us what makes your business unique..."
                        required
                      />
                      <p className="text-sm text-gray-500">
                        This is used by our powerful AI to create personalised
                        content for your website.
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {error && (
                <div className="bg-red-50 border border-red-200 text-red-600 p-4 rounded-lg text-sm">
                  {error}
                </div>
              )}
            </form>

            {/* Add bottom padding for fixed footer */}
            <div className="h-24" />
          </div>
        </div>
      </div>

      {/* Fixed Footer */}
      <div className="fixed bottom-0 left-0 right-0 bg-white border-t">
        <div className="p-6 max-w-2xl mx-auto">
          <Button
            onClick={handleSubmit}
            className="w-full h-12 flex items-center justify-center gap-2"
            disabled={isGenerating}
          >
            {isGenerating ? (
              <>
                <Loader2 className="h-5 w-5 animate-spin" />
                <span className="ml-2">{messages[messageIndex]}</span>
              </>
            ) : (
              <>
                <Sparkles className="h-5 w-5" />
                <span>Create My Website</span>
                <ArrowRight className="h-5 w-5 ml-2" />
              </>
            )}
          </Button>

          {isGenerating && (
            <div className="mt-4">
              <div className="h-1 bg-gray-100 rounded-full overflow-hidden">
                <div
                  className="h-full bg-blue-600 rounded-full transition-all duration-500"
                  style={{
                    width: `${((messageIndex + 1) / messages.length) * 100}%`,
                  }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InitialForm;
