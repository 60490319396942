// src/components/CMSView/Preview/ServicesSection.jsx
import React from "react";
import { ArrowRight } from "lucide-react";

const ServicesSection = ({ title, items = [] }) => {
  const displayItems = Array.isArray(items) ? items : [];

  return (
    <div className="py-24 bg-white">
      <div className="max-w-7xl mx-auto px-6 lg:px-8">
        {/* Section Header */}
        <div className="max-w-2xl mx-auto text-center mb-16">
          <h2 className="text-3xl md:text-4xl font-bold text-gray-900 mb-4">
            {title}
          </h2>
          <div className="w-20 h-1 bg-blue-600 mx-auto"></div>
        </div>

        {/* Services Grid */}
        <div className="grid md:grid-cols-3 gap-8 lg:gap-12">
          {displayItems.map((service, index) => (
            <div
              key={service.title || index}
              className="group relative overflow-hidden"
            >
              {/* Image Container */}
              <div className="aspect-[4/5] overflow-hidden rounded-xl mb-6 relative">
                <img
                  src={service.image?.url || "/img/service.jpg"}
                  alt={service.image?.alt || service.title}
                  className="w-full h-full object-cover transform group-hover:scale-105 transition-transform duration-500"
                />
                {/* Image Credit */}
                {service.image?.credit && (
                  <a
                    href={service.image.credit.link}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="absolute bottom-2 right-2 text-xs text-white/70 bg-black/30 px-2 py-1 rounded hover:bg-black/50"
                  >
                    Photo: {service.image.credit.name}
                  </a>
                )}
              </div>

              {/* Content */}
              <div className="space-y-4">
                <h3 className="text-xl font-semibold text-gray-900">
                  {service.title}
                </h3>
                <p className="text-gray-600 leading-relaxed">
                  {service.description}
                </p>

                {/* Learn More Link */}
                <div className="pt-2">
                  <a
                    href="#"
                    className="inline-flex items-center text-blue-600 hover:text-blue-800 font-medium group"
                  >
                    Learn more
                    <ArrowRight className="h-4 w-4 ml-1 transform group-hover:translate-x-1 transition-transform" />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServicesSection;
